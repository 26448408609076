<template>
    <section class="section_1">
        <div>
            <h3 class="colorTitle">SOBRE NOSOTROS</h3>
            <p class="colorSubtitle">Protegiendo los productos que la gente ama y utiliza
                cada día.</p>
            <p class="colorText">Esta es la esencia de lo que hacemos. Nuestra meta es
                romper con una industria y dar una mayor tranquilidad a los "amantes de las
                cosas" de toda Latinoamérica. Al tiempo que fomentamos, en el camino, una
                cultura interna excepcional de aprendizaje, trabajo en equipo y creatividad.
            </p>
        </div>
    </section>
</template>
<script>
import slicesection1 from '../assets/slice_section1.png';

export default {
    name: 'SectionNosotros1',
    data: () => ({
        slicesection1
    })
}
</script>
<style lang="scss" scoped>
#nosotros1 {
    padding-left: 5%;
    padding-bottom: 5%;
}

#imgSlice {
    display: block;
    margin: 0px auto;

}

.colorTitle {
    color: #ff0055;
    padding-top: 3%;
    font-size: 40px;
}

.colorSubtitle {
    color: #19055b;
    font-weight: bold;
}

.colorText {
    color: #19055b;
}

.section_1 {
    display: flex;
    margin-bottom: 8rem;

    div {
        margin-left: 5%;
        // padding-top: 10%;
        margin-top: 5%;

        h1 {
            font-style: normal;
            font-weight: bold;
            font-size: 55px;
            line-height: 125%;
            text-align: start;
        }

        p {
            font-style: normal;
            font-size: 20px;
            line-height: 36px;
            text-align: start;
            padding-right: 5%;
            
        }
    }
}
@media (max-width: 768px) {
  .section_1 {
    flex-direction: column;

    div {
      width: 85%;
      text-align: center;
      padding-top: 30%;

      h2 {
        width: 100%;
      }
    }

    img {
      width: 80%;
      margin-top: 2rem;
    }
  }
}
</style>



