import Vue from "vue"; 
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Form from "../views/Form.vue";
import Chat from "../views/Chat.vue"
import Info from "../views/SectionInfo.vue"
import Api from "../views/API.vue";
import Nosotros from "../views/Nosotros.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: '/form',
    name: 'Form',
    component: Form
  },
  {
      path: '/chat',
      name: 'Chat',
      component: Chat
  },
  {
      path: '/info-gext-sbi',
      name: 'Info',
      component: Info
  },
  {
    path: '/api',
    name: 'API',
    component: Api
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: Nosotros
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;