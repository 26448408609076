<template>
  <div id="contenidoPadre" class="section_1" > 
    <div id="contenido">
      <h1><img id="imagen" :src="SecurityImg" alt="SecurityImg">La API de EmbedX</h1>
      <p>¡Bienvenido a EmbedX! En esta página encontrarás información relevante para comenzar a trabajar con nuestra API :)</p>
      <p>La <b>gran mayoría</b> de las llamadas a nuestra API son de carácter <b>privado,</b> por lo cual se requiere autenticación. El acceso lo controlamos a través del uso de una combinación de <b>API key y API secret.</b> ¡No compartas estas credenciales con nadie!</p>   
        <br/>
        <h1>Documentación</h1>
      <p>La <a href="https://perk.cl/vault-perk/api/v1/docs/" target="_blank" style="color: #fe3370" >API SHIELD</a> permite realizar operaciones relativas a la <b>gestión de usuarios y personas.</b> Una vez tengas tu usuario de EmbedX activo, puedes crear <b>personas</b> a través de SHIELD para comenzar a asegurarlas.</p>
      <p>Vault-EmbedX, cuya documentación puedes revisar <a href="https://perk.cl/vault-perk/api/v1/docs/" target="_blank" style="color: #fe3370">aquí</a>, permite obtener la información de los <b>planes de seguros disponibles</b> y <b>asegurar</b> a las personas que creaste en SHIELD.</p>
    </div>
    
  </div>
</template>

<script>
import SecurityImg from '../assets/landing_security.png';

export default {
    name: 'landing_security',
    data: ()=>({
        SecurityImg
    })
}
</script>

<style scoped>

#contenidoPadre{
  padding-top: 8%;
  padding-left: 8%;
  padding-right: 8%;

}
#contenido{
  padding-left: 20px;
}


#imagen{
  width: 200px;
  float: right;
  padding-bottom: 18%;
  padding-right: 3%;
  padding-left: 2%;
}


</style>
