<template>
  <nav class="nav">
    <router-link to="/" style="display: flex;align-items: center;justify-content: center;">
      <img :src="Logo" alt="logo">
    </router-link>
    <div class="nav_opt">
      <div class="options">
        <router-link to="/">
          <a href="#">Home</a>
        </router-link>
        <a href="https://embedx.io/blog" target="_blank">Blog</a>
        <router-link to="/nosotros" @click.native="scrollToTop">
          <a href="#">Nosotros</a>
        </router-link>
        <a href="/#Seguros">Seguros</a>
        <a href="/#Contactanos">Contáctanos</a>
        <router-link to="/chat">
          <a href="#">Usa tu Seguro</a>
        </router-link>
        <a class="hidden" href="#">LogIn</a>
      </div>
      <button id="btnLogin">
        <a @click="redireccion('Login')" id="linkDashboard">
            Login
          </a>
      </button>
    </div>
    
  </nav>
</template>

<script>
import Logo from '../assets/logo.svg';

export default {
    name: 'Header',
    data: ()=>({
        Logo
    }),
    mounted () {
        this.entorno = process.env.VUE_APP_ENTORNO
    }, 
    methods: { 
    scrollToTop() {
    window.scrollTo(0,0);
  },
  redireccion(vista){
    switch(vista){
      case 'Login' : 
        this.entorno = process.env.VUE_APP_ENTORNO
        window.location.href = this.entorno;
        break;
    }
  }
}
}
</script>

<style lang="scss">

.nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
  padding: 1.5rem 0;
  background: rgba(255, 255, 255, 0.007);
  transition: background 0.5s;
  img{
    margin-left: 5rem;
    width: 50%;
  }
  .nav_opt{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 5%;
    width: 35%;
    .options{
        padding-right: 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hidden{
          display: none;
        }
        a{
          text-decoration: none;
          color: #61646F;
          font-size: 15px;
          margin-right: 0.8rem;
          &:hover{
            color: #000
          }
          
        }

    }
    button{
      background: #fff;
      border: 1px solid #AFC0FA;
      color: #61646F;
      transition: background 1s, border-color 1s;
      width: 100px;
      &:hover{
        background: #AFC0FA;
        border-color: #fff;
      }
    }
  }
}
.nav_scrolled{
  background-color: #fff
}

@media (max-width: 1024px) {
  .nav{
    .nav_opt{
      width: unset;
      .options{
        padding: 0;
      }

    }
  }
}

@media (max-width: 768px) {
  .nav{
    flex-direction: column;
    padding-top: 0.5rem;
    img{
      width: 40%;
      margin: 0;
      margin-bottom: 5px;
    }
    .nav_opt{
      button{
        display: none;
      }
      .options{
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .hidden {
            display: unset;
          }
        }
      }
    }
}

@media (max-width: 425px) {
  .nav{
    padding: 1rem 0;
    background: #fff;
    img{
      width: 45%;
    }
    .options{
      width: 95%;
      a{
        margin-top: 8px;
      }
    }
  }
}

#btnLogin{
    background: #FE195E;
    border-radius: 8px;
    color: #fff;
    border: 1px solid #FE195E;
    padding: 15px 18px;
    cursor: pointer;
    transition: color 1s, background 1s;
    
}
#btnLogin:hover{
        background: #fff;
        color: #FE195E;
        #linkDashboard{
        color: #FE195E;
        
}
}
#linkDashboard{
        color: #fff;
        text-decoration: none;
}

</style>
