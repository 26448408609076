<template>
  <section>
    <div class="nosotros4">
      <div>
        <div>
          <h2 class="colorTitle">Nos apoyan</h2>
        </div>
        <div>
          <img class="imgPartners1" :src="startup" alt="logostartup">
          <img class="imgPartners2" :src="digitalventures" alt="logodigitalventures">
          <img class="imgPartners3" :src="southbridge" alt="logosouthbridge">
        </div>
      </div>
    </div>
  </section>

</template>
<script>

import startup from '../assets/logost.png';
import digitalventures from '../assets/logodv.png';
import southbridge from '../assets/logosb.png';


export default {
  name: 'SectionNosotros4',
  data: () => ({
    startup,
    digitalventures,
    southbridge
  })
}
</script>
<style scoped>
.nosotros4 {
  padding-left: 5%;
  padding-top: 1px;
  padding-bottom: 4%;
}

.colorTitle {
  color: #19055b;
  padding-bottom: 5%;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 125%;
  text-align: start;
  padding-left: 5%;
}

.imgPartners1 {
  margin-left: 10%;
  width: 280px;
}

.imgPartners2 {
  margin-left: 10%;
  width: 280px;
}

.imgPartners3 {
  margin-left: 10%;
  width: 280px;
}


@media (max-width: 768px) {
  .nosotros4 {
    flex-direction: column;
  }

  .imgPartners1 {
  margin-left: 15%;
  width: 200px;
}

.imgPartners2 {
  margin-left: 15%;
  width: 200px;
}

.imgPartners3 {
  margin-left: 15%;
  width: 200px;
}
}

/* .nosotros4 {
    flex-direction: column;

    img {
      width: 80%;
      margin-top: 2rem;
    }
  } */
</style>




