<template>
  <div>
    <div class="hello" id="test">
    
    </div>
  </div>
</template>


<script>
// import Header from '@/components/Header.vue'

import { run } from 'tripetto-runner-chat';
import Services from 'tripetto-services';
const { definition, styles, l10n, locale, translations, attachments, onSubmit } = Services.init({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoieVNIMTBJbVFuaE9mTmJtSVhkRzJCT2swRjRMd09PZ2R5cGlFSWorZk8zRT0iLCJkZWZpbml0aW9uIjoiQ3RlR1FwM1BnNDkvNytOTHN5OGJZSjBaTHU4MzV6aDljL2cvZnN5U1gycz0iLCJ0eXBlIjoiY29sbGVjdCJ9._K4mS6WGam_nyPcQLSVyTYEPHe1gDuu1Vv5Uc1zPs0s' });

export default {
  name: 'Chat',
//   components: {
//     Header
//   },
  mounted: ()=>{
    run({
      element: document.getElementById('test'), /* Or supply your own element here */
      definition,
      styles,
      l10n,
      locale,
      translations,
      attachments,
      onSubmit
    }).then(()=>{
      let nav = [... document.getElementsByClassName('nav')][0];
    
      let test = document.getElementById('test');
      if (test){
        test.style = `margin-top: ${nav.getBoundingClientRect().bottom+10}px`;
      }
    });
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// .dxydua{
//   background: #f4f4f4 !important;
// }
.hello{
    margin-top: 5rem;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
