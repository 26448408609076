<template>
        <section class="section_7">
      <h2>FAQ</h2>

      <div class="container">
        <div >
          <div class="btn" @click="showDetail">
            +
          </div>
          <h3 @click="showDetail">
            Soy una plataforma: Cómo gano con esto?
          </h3>
        </div>
        <div class="finder detail">
          Ganarás una comisión por cada seguro o garantía vendida, sin tener que hacer ninguna gestión 
          de venta o post venta, es super simple, te tienes que integrar y nosotros hacemos el resto.
        </div>
        
      </div>
      <div class="container" >
        <div  >
          <div class="btn" @click="showDetail">
            +
          </div>
          <h3 @click="showDetail">
            Es gratis instalar el plugin?
          </h3>
        </div>
        <div class="finder detail">
          Sip, no te cobramos ni por la instalación ni por la mantención, sólo nos llevamos un 5% de tu comisión por ventas
        </div>
        
      </div>

      <div class="container" >
        <div  >
          <div class="btn" @click="showDetail">
            +
          </div>
          <h3 @click="showDetail">
            Cuánto demora integrar la venta de seguros a las plataformas?
          </h3>
        </div>
        <div class="finder detail">
          Si te integras vía plugin, te va a tomar sólo un par de minutos. También tenemos la posibilidad de que te 
          integres vía API, la cual está 100% documentada, hecha por desarrolladores, para desarrolladores.
        </div>
      </div>

      <div class="container" >
        <div  >
          <div class="btn" @click="showDetail">
            +
          </div>
          <h3 @click="showDetail">
            En qué plataformas de e-commerce trabajamos?
          </h3>
        </div>
        <div class="finder detail">
          Actualmente tenemos plugins operativos en VTEX, Magento, Shopify, Jumpseller y WooCommerce. Estamos trabajando para crear plugins en 
          las demás, así que si tu tienda está construida en alguna otra plataforma, contáctanos!
        </div>
      </div>

      <div class="container" >
        <div  >
          <div class="btn" @click="showDetail">
            +
          </div>
          <h3 @click="showDetail">
            Qué seguros se pueden integrar a las plataformas?
          </h3>
        </div>
        <div class="fonder detail">
          Garantías extendidas para productos, seguro oncológico, seguro de mascotas, seguro de viajes, seguro de deportistas
        </div>
      </div>

      <div class="container" >
        <div  >
          <div class="btn" @click="showDetail">
            +
          </div>
          <h3 @click="showDetail">
            Cómo sé si los productos que vendo en mi e-commerce califican para agregar garantías extendidas?
          </h3>
        </div>
        <div class="finder detail">
          Son garantizables los productos electrónicos, línea blanca, muebles, herramientas, computación, consolas y 
          muchos productos más. Si te interesa saber sobre alguno en especial envíanos un correo a 
          <a href="maito:ventas@embedx.io">ventas@embedx.io</a> o háblanos por nuestro chat.
        </div>
      </div>

    </section>
</template>

<script>

export default {
    name: 'Section7',
    methods: {
    showDetail: function(event){
     // Get vars
      let parent = event.target.parentNode;
      let btn = [... parent.getElementsByClassName('btn')][0]
      parent = parent.parentNode;
      let detail = [... parent.getElementsByClassName('detail')];

      // Chequea si ya está activo
      if(detail.length >= 1){
        detail[0].className = 'finder detail_display'
        btn.innerText = '-'
      } else {
        let displayed = [... parent.getElementsByClassName('finder detail_display')][0]
        displayed.className = 'finder detail';
        btn.innerText = '+'
      }

    }
  }
}
</script>

<style lang="scss" scoped>

.section_7{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
  h2{
    width: 68%;
    text-align: start;
  }
  background: #5678f53a;
  .container{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 1rem 0;
    padding: 2rem 3rem;
    border-radius: 5px;
    background: #fff;
    div{
      display: flex;
      flex-direction: row;
      align-items: center;

      .btn{
        cursor: pointer;
        font-size: 30px;
        font-weight: 100;
        margin: 10px;

      }
    }
    
    .detail{
      display: none;
    }
    .detail_display{
      display: inline;
      margin-left: 5%;
      padding-left: 1%;
      border-left: 2px solid #212225;
    }
  }
}
</style>