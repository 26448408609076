<template>
    <section class="section_4">
        <div>
            <h2>EmbedX usa tecnología para modernizar la industria aseguradora</h2>
            <p>Nuestra tecnología permite que cualquier plataforma pueda integrar fácilmente seguros o garantías extendidas sin alterar sus flujos de venta, y sin gestionar su venta ni post venta, ya que todos nuestros procesos están 100% automatizados</p>
            <router-link to="/api" tag="button">Documentación API</router-link>
        </div>
        <img :src="Section4Img" alt="integrations">
        
    </section>
</template>

<script>
import Section4Img from '../assets/section_4.png';

export default {
    name: 'Section4',
    data: ()=>({
        Section4Img
    })
}
</script>

<style lang="scss" scoped>
.section_4{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5678f53a;
    padding: 3rem 0;
    div{
        width: 40%;
        
    }
    img{
        width: 50%;
    }
    button{
        margin-top: 2rem;
    }
}

@media (max-width: 768px) {
    .section_4{
        flex-direction: column;
        div{
            width: 80%;
            text-align: center;
            h2{
                width: 100%;
            }
        }

        img{
            width: 80%;
            margin-top: 2rem;
        }
    }
}
</style>