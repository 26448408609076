<template>
  <div class="section_1"> 
    <div class="formEbedx" id="testForm"  >
    
    </div>
  </div>
</template>


<script>
// import Header from '@/components/Header.vue'

import { run } from 'tripetto-runner-autoscroll';
import Services from 'tripetto-services';
const { definition, styles, l10n, locale, translations, attachments, onSubmit } = Services.init({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiTy9scDA0MVdZYnAwZG5wTmtncEFGQStiWDVzcnEyNlVia1ZMTkFibU5Edz0iLCJkZWZpbml0aW9uIjoibnh3MVRrUzBCK3BCaGN2Q2drZkdlVXI2SjBDbHlDNWxieGFmaENpSDNncz0iLCJ0eXBlIjoiY29sbGVjdCJ9.1bqAUIwtGWu3VSUHLdcUbs-pUL_3ziv1l33OO7soErY' });

export default {
  name: 'Form',
//   components: {
//     Header
//   },
  mounted: ()=>{
    run({
      element: document.getElementById('testForm'), /* Or supply your own element here */
      definition,
      styles,
      l10n,
      locale,
      translations,
      attachments,
      onSubmit
    }).then(()=>{
      let nav = [... document.getElementsByClassName('nav')][0];
    
      let test = document.getElementById('testForm');
      if (test){
        test.style = `margin-top: ${nav.getBoundingClientRect().bottom+10}px`;
      }
    });
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// .dxydua{
//   background: #f4f4f4 !important;
// }

#testForm{
padding-top: 3%;
padding-left: 3%;
padding-right: 3%;

}
</style>
