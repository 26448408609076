<template>
  <section class="section_7">
    <p></p>
    <p></p>
    <p></p>
    <p></p>
    <h1>TODO LO QUE NECESITAS SABER DE LAS GARANTÍAS EXTENDIDAS</h1>

    <div class="container">
      <div>
        <div class="btn" @click="showDetail">+</div>
        <h3 @click="showDetail">¿Qué es una garantía extendida?</h3>
      </div>
      <div class="finder detail">
        El Servicio de Extensión de Garantía te protege frente a fallas
        mecánicas y electrónicas del artículo que compraste y ampara las
        reparaciones que sean necesarias, durante la vigencia del plan que
        contrataste. La vigencia del Servicio de Extensión de Garantía otorgada
        por este documento comienza al expirar la garantía original del
        fabricante y no se extiende a desperfectos o fallas excluidas por la
        garantía original del producto. En caso de que el producto cuente con
        más de un plazo de garantía original, el Servicio de Extensión de
        Garantía comenzará a regir una vez vencida la primera garantía del
        fabricante. Siempre y cuando la falla se haya producido durante la
        vigencia indicada, los beneficios otorgados por el servicio de Extensión
        de Garantía son:
        <p></p>
        <li>Llamada gratuita a la línea 800 200 311.</li>
        <p></p>
        <li>
          Reparación del producto fallado durante la vigencia del Servicio de
          Extensión de Garantía, incluyendo repuestos y mano de obra. Los
          productos deberán ser ingresados por el cliente al servicio técnico
          asignado al momento del llamado para notificar la falla.
        </li>
        <p></p>
        <li>Ampara fallas ocasionadas por variaciones de voltaje.</li>
      </div>
    </div>

    <div class="container">
      <div>
        <div class="btn" @click="showDetail">+</div>
        <h3 @click="showDetail">¿Cómo se usa?</h3>
      </div>
      <div class="finder detail">
        Si el producto falla dentro del periodo cubierto por el Servicio de
        Extensión de Garantía, el cliente deberá llamar a la línea 800 200 311 o
        al 2 2782 5905 desde celulares, de lunes a viernes de 09:00 a 18:30
        horas (excepto festivos), donde recibirá toda la ayuda y orientación
        para hacer uso del Servicio de Extensión de Garantía.
        <p></p>
        La boleta de compra y el certificado serán requisitos indispensables
        para hacer valer su Servicio de Extensión de Garantía.
        <p></p>
        Podrás solicitar el cambio de producto después de la segunda reparación,
        siempre y cuando el servicio técnico autorizado haya emitido un tercer
        diagnóstico de falla de fabricación. Se entiende por “reparación” el
        reemplazo o reparación de piezas y/o partes del producto que presenten
        fallas de tipo mecánico, eléctrico o electrónico, en las condiciones que
        garantiza el respectivo manual del fabricante, a través de un servicio
        técnico seleccionado. Las reparaciones se llevarán a cabo con repuestos
        nuevos o reacondicionados, equivalentes en rendimiento y confiabilidad.
        <p></p>
        El cliente podrá solicitar el cambio de producto cuando el plazo de
        reparación exceda 21 días corridos, contados desde el día en que el
        producto ingresó a servicio técnico.
        <p></p>
        El valor máximo a pagar, en caso del reemplazo del producto, será el
        indicado en la boleta de compra, sin incluir el valor del Servicio de
        Extensión de Garantía ni cargos por financiamiento.
        <p></p>
        El cambio de producto deberá ser por un producto existente en la tienda
        en que lo compraste, en caso de que éste no se encuentre disponible en
        tienda, el Servicio de Extensión de Garantía tendrá a su solo juicio la
        opción de pagar al cliente en dinero en efectivo. Este monto no podrá
        exceder el valor del producto original registrado en la boleta, sin
        incluir el valor del Servicio de Extensión de Garantía.
      </div>
    </div>

    <div class="container">
      <div>
        <div class="btn" @click="showDetail">+</div>
        <h3 @click="showDetail">¿Qué no cubre?</h3>
      </div>
      <div class="finder detail">
        El servicio de Garantía Extendida no opera ante: Fallas ocurridas
        durante el período de garantía de fábrica.
        <p></p>
        Fallas expresamente excluidas en el manual del fabricante.
        <p></p>
        Fallas y/o daños atribuibles al consumidor, por ejemplo, aquellos
        derivados de descuido, mal uso o abuso del producto, uso comercial y/o
        no doméstico, accidentes y/o golpes.
        <p></p>
        Fallas y/o daños por incendio, robo, pérdida, vandalismo, terrorismo,
        sulfatación, oxidación, corrosión, derivados de riesgos de la naturaleza
        (terremoto, lluvia, maremoto, inundación, granizo, nieve, etc.), derrame
        de líquidos, accidentes, caídas, golpes accidentales o intencionales.
        <p></p>
        Daños y/o fallas originadas en el traslado del producto.
        <p></p>
        Fallas que no afecten al hardware (componentes físicos y tangibles) del
        equipo.
        <p></p>
        Productos de consumo como tintas, pilas y baterías, entre otros.
        <p></p>
        Respaldo de información de cualquier tipo. Será responsabilidad del
        cliente el respaldar la información antes del ingresar un producto a
        servicio técnico.
        <p></p>
        Productos con su número de serie alterado e/o intervención del producto
        por un servicio técnico o personal no autorizado.
        <p></p>
        Pantallas dañadas o quebradas.
        <p></p>
        Cualquier tipo de accesorios tales como cargadores, audífonos u otros.
        <p></p>
        Fallas y/o daños derivados del uso no doméstico, con propósitos
        comerciales o arriendo público.
        <p></p>
        Daños y/o perjuicios que pudieran sufrir personas o bienes, como una
        consecuencia directa o indirecta del uso o imposibilidad de usar los
        productos cubiertos.
        <p></p>
        Mantenciones de cualquier tipo, limpieza, ajuste, alineamiento,
        programación e/o indicaciones de uso del producto adquirido.
        <p></p>
        Partes y piezas que, por su uso, sufren desgaste natural.
        <p></p>
        Aspectos externos del producto adquirido, tales como pintura u otros.
        <p></p>
        Fallas de transmisión y recepción debido a causas externas.
        <p></p>
        Las exclusiones mencionadas en la garantía original del fabricante.
        <p></p>
        Alteraciones no autorizadas del producto adquirido o defectos derivados
        por incumplimiento de las instrucciones de instalación, operación o
        mantenimiento dadas en forma escrita por el fabricante.
        <p></p>
        No considera instalación, reinstalación o servicio a domicilio.
      </div>
    </div>

    <div class="container">
      <div>
        <div class="btn" @click="showDetail">+</div>
        <h3 @click="showDetail">¿Cómo funciona el plan de reemplazo?</h3>
      </div>
      <div class="fonder detail">
        Programa de reemplazo aplica para todo producto cuyo valor sea inferior
        a $60.000.
        <p></p>
        Cambio de producto ante una falla que impida el correcto funcionamiento.
        El producto será revisado por el servicio de Extensión de Garantía antes
        de ser aprobado su reemplazo.
        <p></p>
        El nuevo producto podrá ser de menor valor al producto original indicado
        en la boleta de compra, siempre y cuando el producto nuevo cumpla con
        las características principales del producto original y el valor máximo
        a pagar será el registrado en la boleta sin incluir el valor del
        Servicio de Extensión de Garantía ni gastos por financiamiento.
        <p></p>
        La Compañía tendrá a su solo juicio la opción de pagar el monto en
        dinero efectivo o de reemplazar o reponer el o los productos.
        <p></p>
        No se podrá exigir a la Compañía que los objetos que haya repuesto sean
        iguales a los que existían antes de la solicitud de reparación, y se
        entenderán cumplidas válidamente sus obligaciones al restablecer, en
        forma razonablemente equivalente las cosas aseguradas al estado en que
        estaban al momento de la solicitud de reparación.
        <p></p>
        La boleta o factura de compra y este documento son necesarios para
        recibir el Servicio de Extensión de Garantía.
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Section7",
  methods: {
    showDetail: function (event) {
      // Get vars
      let parent = event.target.parentNode;
      let btn = [...parent.getElementsByClassName("btn")][0];
      parent = parent.parentNode;
      let detail = [...parent.getElementsByClassName("detail")];

      // Chequea si ya está activo
      if (detail.length >= 1) {
        detail[0].className = "finder detail_display";
        btn.innerText = "-";
      } else {
        let displayed = [
          ...parent.getElementsByClassName("finder detail_display"),
        ][0];
        displayed.className = "finder detail";
        btn.innerText = "+";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section_7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
  h2 {
    width: 68%;
    text-align: start;
  }
  background: #5678f53a;
  .container {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 1rem 0;
    padding: 2rem 3rem;
    border-radius: 5px;
    background: #fff;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      .btn {
        cursor: pointer;
        font-size: 30px;
        font-weight: 100;
        margin: 10px;
      }
    }

    .detail {
      display: none;
    }
    .detail_display {
      display: inline;
      margin-left: 5%;
      padding-left: 1%;
      border-left: 2px solid #212225;
    }
  }
}
</style>