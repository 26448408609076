<template>
    <section class="section_3">
        <div class="heading">
            <h2>Quiénes ocupan EmbedX?</h2>
            <p>
            Todas estas plataformas están aumentando sus ingresos gracias a nuestras integraciones
            </p>
        </div>
        <div>
          <img :src="Section3Img1" alt="">
          <img :src="Section3Img2" alt="">
            <!-- <img class="img" :src="Section3Img" alt="brands">
            <div class="images">
              <img :src="Section3ImgMov" alt="">
            </div> -->
        </div>
    </section>
</template>

<script>
// import Section3Img from '../assets/section_3.png'
// import Section3ImgMov from '../assets/section_3_mov.png'

import Section3Img1 from '../assets/clientesActualizadosResponsive1.png'
import Section3Img2 from '../assets/clientesActualizadosResponsive2.png'

export default {
    name: 'Section3',
    data: ()=>({
        // Section3Img,
        // Section3ImgMov,
        Section3Img1,
        Section3Img2
    })
}
</script>

<style lang="scss" scoped>

.section_3{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
  .heading{
    width: 40%;
    text-align: center;
    p{
      font-size: 18px;
    }
  }
  div:last-child{
    display: flex;
    justify-content: center;
    img{
      width: 65%;
      &:last-child{
        display: none;
      }
    }
    // .img{
    //   max-width: 100%;
    // }
    // .images{
    //   display: none;
    // }
  }
}

@media screen and (max-width: 768px) {
  .section_3{
    .heading{
      width: 80%;
    }
    div{
      img{
        width: 80%;
      }
    }
    // div{
    //   .img{
    //     display: none;
    //   }
    //   .images{
    //     display: flex;
    //     flex-direction: column-reverse;
    //     align-items: center;
    //     img{
    //       width: 60%;
    //     }
    //   }

    // }
  }
}

@media (max-width: 425px) {
  .section_3{
    div:last-child{
      flex-direction: column;
      align-items: center;
      img{
        display: none;
        &:last-child{
          display: unset;
          width: 80%;
        }
      }
      // .images{
      //   img{
      //     width: 90%;
      //   }
      // }
    }
  }
}
</style>