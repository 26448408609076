<template>
  <div id="contenidoNosotros">
    <SectionNosotros1 />
    <SectionNosotros2 />
    <SectionNosotros3 />
    <SectionNosotros4 />

  </div>

</template>

<script>
import SectionNosotros1 from '../components/SectionNosotros1.vue';
import SectionNosotros2 from '../components/SectionNosotros2.vue';
import SectionNosotros3 from '../components/SectionNosotros3.vue';
import SectionNosotros4 from '../components/SectionNosotros4.vue';

export default {
  name: 'nosotros',
  data: () => ({
    
  }),
  components: {
    SectionNosotros1,
    SectionNosotros2,
    SectionNosotros3,
    SectionNosotros4

  }
}
</script>
 <!-- <style lang="scss">
    @import url('../styles/main.scss');
  </style>  -->

  <!-- <style scoped>

  #contenidoNosotros{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  width: 100%;

}  

</style> -->

