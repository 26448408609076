<template>
    <footer class="footer">
      <section>
        <div class="footer_section">
          <div>
            <img :src="Logo" alt="Embedex's logo">
            <p>
              Nuestras soluciones digitales nativas permiten ofrecer seguros y garantías de la manera más 
              sencilla posible y con la mejor experiencia de usuario
            </p>
            <div>
              <a href="https://www.linkedin.com/company/perkseguros/" target=”_blank”>
                <img @mouseover="handleHoverRRSS" @mouseleave="handleLeaveRRSS" :src="Linkedin" alt="linkedin link">
              </a>
            </div>
          </div>
          <div>
            <h3>Nosotros</h3>
            <router-link to="/nosotros" @click.native="scrollToTop">
              <a href="/">Sobre nosotros</a>
            </router-link>
            <a href="https://embedx.io/blog" target="_blank">Blog</a>
          </div>

          <div>
            <h3>Soporte</h3>
            <a href="/#Contactanos">Contáctanos</a>
            <a href="/#FAQ">FAQs</a>
          </div>
          <div>
            <h3>Clientes</h3>
            <router-link to="/form">
              <a href="#">Formulario</a>
            </router-link>
          </div>
        </div>

      </section>
      <section>
        <hr>
        <p>Copyright © 2022. EmbedX. Todos los derechos reservados </p>
      </section>
    </footer>
</template>

<script>
import Logo from '../assets/logo.svg';
import Linkedin from '../assets/icon_li.svg';

import LinkedinH from '../assets/icon_lih.svg';

export default {
    name: 'Footer',
    data: ()=>({
    Logo,

    Linkedin
    }),
    methods: {
        handleHoverRRSS: function(event){
            let src = event.target.src;
            let url = src.split('/img')[0];

            url = url + LinkedinH;

            event.target.src = url;
        
        },
        handleLeaveRRSS: function(event){
            let src = event.target.src;
            let url = src.split('/img')[0];

            url = url + Linkedin;

            event.target.src = url;
        
        },
        scrollToTop() {
        window.scrollTo(0,0);
       }
    },

}
</script>

<style lang="scss" scoped>
.footer{
  section:first-child{
    display: flex;
    justify-content: center;
    .footer_section{
      background-image: url("../assets/footer_desk.svg");
      background-position: center;
      background-size: cover;
      padding-top: 5rem;
      width: 75%;
      display: flex;
      justify-content: space-around;
      div{
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &:first-child{
          margin-right: 1rem;
        }
        div{
          flex-direction: row;
          width: 100%;
          a{
            width: 15%;
            img{
              width: 100%;
            }
          }
        }
        h3{
          margin: 0;
        }
        a{
          margin-top: 1rem;
          text-decoration: none;
          color: #212225
        }
        img{
          width: 40%;
        }
        p{
          width: 85%;
          color: #414347;
          text-align: start;
        }
      }
    }
  }
  section{
    color: #818592;
    text-align: center;
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 1024px) {
  .footer{
    section:first-child{
      .footer_section{
        width: 50%;
        background-image: url("../assets/footer.svg");
        flex-direction: column;
        align-items: center;
        div{
          align-items: center;
          margin-bottom: 1rem;
          &:first-child{
            margin-right: 0;
            div{
              justify-content: center;
            }
          }
          img{
            width: 70%;
          }
          p{
            width: 100%;
            text-align: center;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .footer{
    section:first-child{
      display: flex;
      flex-direction: column;
      align-items: center;
      .footer_section{
        justify-content: center;
        width: 90%;
        div{
          align-items: center;
          p{
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .footer{
    section{
      width: 100%;
      &:first-child{
        .footer_section{
          flex-direction: column;
          background-image: unset;
          div{
            width: unset;
            img{
              width: 40%;
            }
            h3{
              font-size: 25px;
            }
            // RRSS
            div{
              width: 80%;
              justify-content: center;
            }
          }
          div:first-child{
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
}
</style>