<template>
    <section class="section_6">
        <div>
          <form>
            <h2>Contáctanos</h2>

            <div>
              <input type="text" placeholder="Nombre" v-model="formData.name">
              <input type="text" placeholder="Teléfono" v-model="formData.phone">
            </div>
            
            <input type="email" placeholder="Email" v-model="formData.email">
            <textarea placeholder="Mensaje" name="" id="" cols="30" rows="10" v-model="formData.message"></textarea>
          </form>

          <div class="buttons">
            <button @click="send">Enviar</button>
            <a href="https://calendly.com/embedx" target="_blank">
              <button>Agenda una demo</button>
            </a>
          </div>

        </div>

        <img :src="Section6Img" alt="">
    </section>
</template>

<script>
import Section6Img from '../assets/section_6.png';

export default {
    name: 'Section6',
    data:()=>({
      Section6Img,
      formData: {}
    }),
    methods: {
      send: function(){
        console.log(this.formData)
      }
    }
}
</script>

<style lang="scss" scoped>
.section_6{
  display: flex;
  justify-content: center;
  align-items: center;
  div{
    margin-right: 1rem;
    form{
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
      width: 100%;
      div{
        display: flex;
        justify-content: space-between;
        margin: 0;
        input{
          width: 43%;
          margin-right: 0;
        }
      }
      input{
        padding: 8px;
        border-radius: 5px;
        border: 1px solid #F2F5FC;
        background: #F2F5FC;
        color: #818592;
        margin-bottom: 10px
      }
      textarea{
        padding: 8px;
        border-radius: 5px;
        border: 1px solid #F2F5FC;
        background: #F2F5FC;
        color: #818592;
      }
    }

    .buttons{
      padding: 1rem 0;
      button{
        margin-right: 10px;
        &:last-child{
          margin-left: 0;
          background: #fff;
          color: #000;
          &:hover{
            color: #FE195E;
          }
        }
      }
    }
  }
  img{
    width: 30%;
  }
}

@media (max-width: 768px) {
  .section_6{
    div{
      h2{
        text-align: center;
      }
      form{
        margin: 0;
        width: unset;
        div{
          input{
            width: 43%;
          }
        }
      }

      .buttons{
        display: flex;
        justify-content: center;
      }
    }
    img{
      display: none;
    }
  }
}
</style>
