<template>
  <section class="section_2">
    <div>
      <h3 class="colorTitle">Aquí, ganamos juntos.</h3>
      <p class="colorText">La individualidad y la diversidad de nuestro equipo aportan un verdadero
        valor a EmbedX y ayudan a definir nuestra cultura mientras redefinimos colectivamente un sector.
        Juntos, estamos creando un entorno de aprendizaje, crecimiento e iniciativa. Nos comprometemos a
        contratar y mantener un equipo diverso en la que todxs y cada unx de los miembrxs se sientan que
        son una parte importante de él.
      </p>
    </div>
  </section>
</template>
<script>

export default {
  name: 'SectionNosotros2',
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
.colorTitle {
  color: #ffff;

}

.colorText {
  color: #ffff;
}

.section_2 {
  display: flex;
  margin-bottom: 8rem;

  div {
    // margin-left: 15%;
     padding-top: 14%;
     padding-bottom: 14%;
    // margin-top: 10%;
    background-color: #ff0054FF;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 125%;
      text-align: start;
      padding-left: 5%;
    }

    p {
      font-style: normal;
      font-size: 20px;
      line-height: 36px;
      text-align: start;
      padding-right: 5%;
      padding-left: 5%;
    }
  }
}
</style>




