<template>
  <div id="app">
    <Header/>
    <router-view />
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Footer/>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  created: function() {
    window.addEventListener('scroll', this.handleScroll);

  },
  methods: {
    handleScroll: function(){
      let section_1 = [... document.getElementsByClassName('section_1')][0];
      if (section_1){
        section_1 = section_1.getBoundingClientRect().top
      }

      let test = document.getElementById('test');
      
      if (test){
        test = test.getBoundingClientRect().top
      }

      let nav = [... document.getElementsByClassName('nav')][0]

      if ((section_1 && section_1<0) || (test && test<nav.getBoundingClientRect().bottom)){
        nav.className = "nav nav_scrolled"
      } else {
        nav.className = "nav"
      }
 
    },
    
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
}
</style>
