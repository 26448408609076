<template>
    <section class="section_1">
        <div>
            <h1>Aumenta tus ventas con tus propios seguros integrados</h1>
            <p>Ofrece seguros o garantías extendidas en tu plataforma para que aumentes tu rentabilidad, sin gestiones de venta o post venta.</p>
            <a href="https://calendly.com/embedx" target="_blank">
              <button>Agenda una demo</button>
            </a>
        </div>
        <img :src="Section1Img" alt="">
    </section>

</template>

<script>
import Section1Img from '../assets/section_1alt.png';

export default {
    name: 'Section1',
    data: ()=>({
        Section1Img,
    })
}
</script>

<style lang="scss" scoped>
.section_1{
  display: flex;
  justify-content: space-around;
  background-image: url('../assets/shape.svg');
  background-repeat: no-repeat;
  background-size: contain;
  align-items: flex-start;
  margin-bottom: 8rem;
  div{
    padding-left: 15%;
    padding-top: 5%;
    h1{
      font-style: normal;
      font-weight: bold;
      font-size: 55px;
      line-height: 125%;
      text-align: start;
    }
    p{
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 36px;
      text-align: start;
      padding-right: 10%;
    }
  }

  img{
    width: 40%;
  }
}

@media screen and (max-width: 1224px) {
  .section_1{
    div{
      padding-top: 12%;
    }
    img{
      display: none;
    }
  }
}

@media (max-width: 425px) {
  .section_1{
    padding-top: 25%;
    div{
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1{
        text-align: center;
      }
      p{
        width: 85%;
        padding: unset;
      }
    }
  }
}
</style>