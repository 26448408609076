<template>
    <section class="section_5">
        <img :src="Section5Img" alt="notebook">

        <div>
            <div>
                <img :src="Icon4" alt="logo">
                <p>MASCOTAS</p>
            </div>
            <div>
                <img :src="Icon5" alt="logo">
                <p>MICROMOVILIDAD</p>
            </div>
            <div>
                <img :src="Icon6" alt="logo">
                <p>DEPORTES</p>
            </div>
            <div>
                <img :src="Icon7" alt="logo">
                <p>ROBO</p>
            </div>
            <div>
                <img :src="Icon8" alt="logo">
                <p>GARANTIAS EXTENDIDAS</p>
            </div>
        </div>

        
    </section>
</template>

<script>
import Section5Img from '../assets/section_5.png';
import Icon4 from '../assets/icon4.svg';
import Icon5 from '../assets/icon5.svg';
import Icon6 from '../assets/icon6.svg';
import Icon7 from '../assets/icon7.svg';
import Icon8 from '../assets/icon8.svg';

export default {
    name: 'Section5',
    data: ()=>({
        Section5Img,
        Icon4,
        Icon5,
        Icon6,
        Icon7,
        Icon8
    })
}
</script>

<style lang="scss" scoped>
.section_5{
  display: flex;
  padding: 5rem 0;
  justify-content: center;
  align-items: center;
  img{
    margin-right: 1.5rem;
    width: 40%;
    
  }
  div{
    height: 100%;
    
    div{
      display: flex;
      flex-direction: row;
      margin: 1rem 0;
      img{
        width: 20%;
        margin-right: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .section_5{
    flex-direction: column-reverse;
    padding-left: unset;
    div{
      div{
        img{
          width: 20%;
          margin: 0;
        }
      }
    }

    img{
      width: 80%;
    }
  }
}
</style>