<template>
  <div>
    <section-1/>
    <section-2/>
    <section-3/>
    <section-4/>
    <section-5 id="Seguros"/>
    <section-6 id="Contactanos"/>
    <section-7 id="FAQ"/>
  </div>
</template>

<script>

import Logo from '../assets/logo.svg';

import Section1 from '@/components/Section1.vue';
// How it works
import Section2 from '@/components/Section2.vue';
// Brands
import Section3 from '@/components/Section3.vue';
// Integrations
import Section4 from '@/components/Section4.vue';
// Products
import Section5 from '@/components/Section5.vue';
// Contact
import Section6 from '@/components/Section6.vue';
// FAQ
import Section7 from '@/components/Section7.vue';


export default {
  name: 'HelloWorld',
  data: ()=>({
    Logo
  }),
  components: {
 
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7
  },
  
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import url('../styles/main.scss');

scroll-container {
  scroll-behavior: smooth;
}

</style>
