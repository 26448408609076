<template>
  <section class="section_3">
    <div>
      <h3 class="colorTitle">Conoce al equipo de
        <img class="imgNosotros" :src="nosotrosEmbedx2" alt="nosotos_embedx2">
      </h3>
    </div> 
    <div class="integrantes">
      <div class="integrantes-detail">
        <img :src=this.equipo[0].image alt="imagenequipo">
        <h3>{{this.equipo[0].name}}</h3>
        <h3 class="subtituloIntegrante">{{this.equipo[0].position}}</h3>
        <h4>{{this.equipo[0].info}}</h4>
      </div>
      <div class="integrantes-detail">
        <img :src=this.equipo[1].image alt="imagenequipo2">
        <h3>{{this.equipo[1].name}}</h3>
        <h3 class="subtituloIntegrante">{{this.equipo[1].position}}</h3>
        <h4>{{this.equipo[1].info}}</h4>
      </div>
      <div class="integrantes-detail">
        <img :src=this.equipo[2].image alt="imagenequipo2">
        <h3>{{this.equipo[2].name}}</h3>
        <h3 class="subtituloIntegrante">{{this.equipo[2].position}}</h3>
        <h4>{{this.equipo[2].info}}</h4>
      </div>
    </div>
    <div class="integrantes">
      <div class="integrantes-detail">
        <img :src=this.equipo[3].image alt="imagenequipo">
        <h3>{{this.equipo[3].name}}</h3>
        <h3 class="subtituloIntegrante">{{this.equipo[3].position}}</h3>
        <h4>{{this.equipo[3].info}}</h4>
      </div>
      <div class="integrantes-detail">
        <img :src=this.equipo[4].image alt="imagenequipo2">
        <h3>{{this.equipo[4].name}}</h3>
        <h3 class="subtituloIntegrante">{{this.equipo[4].position}}</h3>
        <h4>{{this.equipo[4].info}}</h4>
      </div>
      <!-- <div class="integrantes-detail">
        <img :src=this.equipo[5].image alt="imagenequipo2">
        <h3>{{this.equipo[5].name}}</h3>
        <h3 class="subtituloIntegrante">{{this.equipo[5].position}}</h3>
        <h4>{{this.equipo[5].info}}</h4>
      </div> -->
      <div class="integrantes-detail">
        <img :src=this.equipo[6].image alt="imagenequipo2">
        <h3>{{this.equipo[6].name}}</h3>
        <h3 class="subtituloIntegrante">{{this.equipo[6].position}}</h3>
        <h4>{{this.equipo[6].info}}</h4>
      </div>
    </div>
    <div>
      <h3 class="colorTitle">Advisors</h3>
    </div>
    <div class="integrantes">
      <div class="integrantes-detail">
        <img :src=this.equipo[7].image alt="imagenequipo">
        <h3>{{this.equipo[7].name}}</h3>
        <h3 class="subtituloIntegrante">{{this.equipo[7].position}}</h3>
        <h4>{{this.equipo[7].info}}</h4>
      </div>
      <div class="integrantes-detail">
        <img :src=this.equipo[8].image alt="imagenequipo2">
        <h3>{{this.equipo[8].name}}</h3>
        <h3 class="subtituloIntegrante">{{this.equipo[8].position}}</h3>
        <h4>{{this.equipo[8].info}}</h4>
      </div>
      <div class="integrantes-detail">
        <img :src=this.equipo[9].image alt="imagenequipo2">
        <h3>{{this.equipo[9].name}}</h3>
        <h3 class="subtituloIntegrante">{{this.equipo[9].position}}</h3>
        <h4>{{this.equipo[9].info}}</h4>
      </div>
    </div> 
  </section>
</template>
<script>

import nosotrosEmbedx2 from '../assets/s.png';
import Gianfranco from '../assets/Gianfranco.jpg';
import Ignacio from '../assets/Ignacio2.png';
import Sebastian from '../assets/Sebastian.jpg';
import daniel from '../assets/daniel.png';
import nacho from '../assets/nacho.png';
import ricardo from '../assets/ricardo.png';
import tere2 from '../assets/tere2.png';
import javier from '../assets/javier.png';
import ramon from '../assets/ramon.png';
import alejandro from '../assets/alejandro.png';


export default {
  name: 'Nosotros2',
  data: () => ({
    nosotrosEmbedx2,
    equipo: [
      {
        name: "Gianfranco Mignanelli",
        image: Gianfranco,
        position: "Cofundador y COO",
        info: "Ingeniero Civil Industrial de la Universidad Adolfo Ibáñez, habla todo el día de Fórmula 1 y películas.",
      },
      {
        name: "Ignacio Ramírez",
        image: Ignacio,
        position: "Cofundador y CEO",
        info: "Ingeniero Civil Industrial de la Universidad de Chile, fanático del tenis, crossfit y Bad Bunny.",
      },
      {
        name: "Sebas Cornejo",
        image: Sebastian,
        position: "Cofundador y Software Ninja",
        info: "Desarma (o a veces rompe) cosas para entender cómo funcionan, escucha metal, y trata de tocar guitarra.",
      },
      {
        name: "Daniel Gajardo B.",
        image: daniel,
        position: "Lead Full Stack Developer",
        info: "Empezó con la programación a los 16 años como hobbie y ahora es su profesión, en el tiempo que queda, se dedica a la familia, los videojuegos y hacer música. Saca lo mejor de sí cuando las cosas no funcionan, hasta hacerlas funcionar.",
      },
      {
        name: "Ignacio Valdebenito",
        image: nacho,
        position: "Full Stack Developer ",
        info: "Le gustan los desafíos, cree que la movilización debería ser por medio de bicicleta. Creencia personal: puedes programar todo lo que imaginas.",
      },
      {
        name: "Ricardo Aguilera",
        image: ricardo,
        position: "Desarrollador Web",
        info: "Le gusta aprender del mundo de las tecnologías, tiene una personalidad tranquila, le gusta analizar el por qué de las cosas, juega videojuegos y hace CrossFit. Cree que una buena comunicación es clave para alcanzar el éxito.",
      },
      {
        name: "Tere Ahumada",
        image: tere2,
        position: "Customer Success Manager",
        info: "Ingeniera Comercial U. Chile de profesión, curiosa por las personas y sus complejidades. Intenta practicar el arte de vivir simple y consciente.",
      },
      {
        name: "Javier Hasbún ",
        image: javier,
        position: "Managing Partner Digital Ventures",
        info: "Emprendedor serial con más de 15 años de experiencia manejando y fundando compañías en Chile y el extranjero.",
      },
      {
        name: "Ramón Heredia",
        image: ramon,
        position: "Director Ejecutivo Digital Ventures",
        info: "Empresario, mentor e inversionista con más de 25 años de experiencia en Servicios Financieros y Transformación Digital en LATAM. Speaker internacional y autor de libros acerca de Nuevas Tecnologías y Servicios Financieros Digitales.",
      },
      {
        name: "Alejandro Ríos",
        image: alejandro,
        position: "Director Ejecutivo Digital Ventures",
        info: "Ejecutivo gerencial de áreas Comerciales, Desarrollo de Productos, Experiencia de Clientes, Canales Digitales y Marketing. Ingeniero de la PUCV, con magister en dirección Comercial y Marketing y diplomados en Comportamiento del Consumidor, Digital MindSet e Innovación, Candidato MBA en el ESE Business School.",
      },
    ],
  })
}
</script>
<style lang="scss" scoped>
.contenidoIntegrantes {
  //  background-color: red;
  // width: 1800px;
  width: 95% // margin-left: 15%;
    // margin-right: 15%;
}

.colorTitle {
  color: #19055b;
  font-size: 40px;
}

.advisors {
  color: #19055b;
  width: 150%;
  font-size: 40px;
  margin-left: 30px;
}

.colorText {
  color: #ffff;
}

.imgNosotros {
  width: 200px;
  border-radius: 0%;
  margin-left: 25px;
  // position: absolute;
  // bottom: -492px;
  // float: right;
  // margin-left: 30px;
}

.section_3 {
  // display: flex;
   float: none;
  // margin-bottom: 8rem;

  div {
    // margin-left: 2.5%;
    //  padding-top: 10%;
    // margin-top: 10%;
    // background-color: #ff0054FF;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 125%;
      text-align: start;
      padding-left: 5%;
      color: #19055b;

    }

    h4 {
      color: #19055b;
    }

    .subtituloIntegrante {
      color: #ff0054FF;
      font-size: 20px;
    }

    p {
      font-style: normal;
      font-size: 20px;
      line-height: 36px;
      text-align: start;
      padding-right: 10%;
      padding-left: 5%;
    }
  }
}

img {
  border-radius: 50%;
  width: 200px;
}

.integrantes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem 0;
  padding-left: 4%;
  padding-right: 4%;

  .integrantes-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      text-align: center;
    }

    h4 {
      text-align: center;
      font-weight: normal;
    }
  }
}

.new_section {
  flex-direction: row;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;

  div {
    width: 40%;
  }

  img {
    width: 200px;
    border-radius: 0%;
    margin-left: 25px;
  }

  button {
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .integrantes {
    flex-direction: column;

    div {
      width: 80%;
      text-align: center;

      h2 {
        width: 100%;
      }
    }

    img {
      width: 80%;
      margin-top: 2rem;
    }
  }
}
</style> 