<template>
    <section class="section_2">
        <img :src="Section2Img" alt="example">
        <div class="benefits">
            <h2>Cómo funciona</h2>
            <div>
                <img :src="Icon1" alt="hh">
                <p>Selecciona el producto que quieres vender</p>
            </div>
            <div>
                <img :src="Icon2" alt="hh">
                <p>Configura la aplicación en tu plataforma</p>
            </div>
            <div>
                <img :src="Icon3" alt="hh">
                <p>Factura mensualmente sin gestión de venta o post-venta</p>
            </div>
        </div>
    </section>
</template>

<script>
import Section2Img from '../assets/section_2.png'
import Icon1 from '../assets/icon1.svg'
import Icon2 from '../assets/icon2.svg';
import Icon3 from '../assets/icon3.svg';

export default {
    name: 'Section2',
    data: ()=>({
        Section2Img,
        Icon1,
        Icon2,
        Icon3
    })
}
</script>

<style lang="scss" scoped>
.section_2{
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 40%;
  }
  .benefits{
    div{
      display: flex;
      margin-bottom: 2rem;
      width: 80%;
      img{
        width: 18%;
        margin-right: 20px;
      }
    }
  }
}

@media (max-width: 1224px) {
  .section_2{

    .benefits{
      div{
        img{
          height: unset;
          width: 20%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section_2{
    flex-direction: column-reverse;
    align-items: center;
    .benefits{
      display: flex;
      flex-direction: column;
      align-items: center;
      h2{
        width: 70%;
      }
    }
    img{
      width: 80%;
      height: unset;
    }
  }
}

@media (max-width: 425px) {
  .section_2{
    .benefits{
      h2{
        text-align: center;
      }
      div{
        flex-direction: column;
        align-items: center;
        p{
          text-align: center;
        }
      }
    }
    img{
      width: 95%
    }
  }
}
</style>